.header {
	background: var(--primary) 0% 0% no-repeat padding-box;
	height: 10rem;
	padding-bottom: 3.5rem;
	margin-bottom: -2rem;
	width: 100%;

	.page-title {
		margin-left: 1rem;
	}

	.control-bar {
		padding: 0.7rem;
	}
}

body.administrator .header {
	background: var(--dark) 0% 0% no-repeat padding-box;
}

.link-white:hover {
	outline: solid 3px rgba(255, 255, 255, 0.65);
}

.utility-dd {
	padding: 0.75rem 0.5rem;

	&.show {
		box-shadow: 0px 3px 6px #00000029;
	}
}

.dropdown-header {
	font-size: 1.05rem;
}

.page-title {
	margin-top: 20px;
	margin-bottom: 10px;
}