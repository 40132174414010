.side-bar {
    nav {
        .nav-link {
            font-family: $font-title;
            border: none;
            background: transparent;
        }
    }
}

@media (min-width: 768px) {
    .side-bar:not(.open) {
        width: 4rem;
        min-width: 4rem;
        max-width: 4rem;

        .logo {
            img {
                width: 2rem;
            }

            padding-bottom: 22px;
        }

        .font-logo-application {
            display: none;
        }

        .font-logo-byline {
            display: none;
        }

        nav {
            padding-left: 0.25rem;
            padding-right: 0.25rem;

            .nav-link {
                padding-left: 0.25rem;
                padding-right: 0.25rem;

                .d-flex.justify-content-start {
                    justify-content: center !important;
                }

                svg {
                    margin-right: 0;

                    &+div {
                        display: none;
                    }
                }
            }
        }

        .sb-footer {
            display: none !important;
        }
    }
}