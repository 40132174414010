div {
    &::-webkit-scrollbar {
        width: 0.75em;
    }
    
    &::-webkit-scrollbar-track {
        background-color: white;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border: 1px solid slategrey;
    }
}
