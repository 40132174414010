@import "./variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --light: #{$light};
  --dark: #{$dark};
  --info: #{$info};
  --warning: #{$warning};
  --danger: #{$danger};
  --success: #{$success};
  --white: #{$white};
  --muted: #{$text-muted};
  --black: #{$black};
  --lessblack: #{$lessblack};
  --link-color: #{$link-color};
  --help: #{$help};
  --brown: #{$brown};
  --gray-94: #{$gray-94};
}

body {
  font-family: $font-content;
}

@import "animation";
@import "buttons";
@import "form";
@import "header";
@import "layout";
@import "scrollbar";
@import "sidebar";
@import "tooltips";
@import "typography";

@import "fixes";


.undo-cell-p {
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.blur {
  backdrop-filter: blur(1px);
  background: rgba(255,255,255,0.5);
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 4;
}

.load-menu-item { 
  background: var(--white);
  &:hover:not(.invalid) {
    cursor: pointer;
    background: rgba(var(--bs-primary-rgb), 0.1);
  }
}
