.feedback.text-danger {
	font-size: 12px;
}

.form-section {
	label {
		text-transform: capitalize;
		font: normal normal 600 14px/16px $font-title;
		color: var(--black);
	}
}

.form-check.form-switch {
	.form-check-input {
		position: relative;
		top: -2px;
		height: 1rem;
	}

	.form-check-label {
		justify-content: flex-start;
	}
}

.form-section.dirty {
	--dirty-color: var(--warning);

	
	border-left: 2px solid var(--dirty-color);
	padding-left: 0.5rem;
	.form-control {
		border-color: var(--dirty-color);
	}
}


.is-invalid {
	label {
		color: var(--danger);
	}

	.form-control {
		border-color: var(--danger);
		padding-right: calc(1.5em + 0.75rem);
		background-image: $form-feedback-icon-invalid;
		background-repeat: no-repeat;
		background-position: right calc(0.375em + 0.1875rem) center;
		background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	}

	.form-select {
		border-color: #f24236;
	}

	.form-select:not([multiple]):not([size]),
	.form-select:not([multiple])[size="1"],
	.dd-form-select {
		padding-right: 4.125rem;
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e"),
			$form-feedback-icon-invalid;
		background-position: right 0.75rem center, center right 2.25rem;
		background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	}
}
