$primary: #025aa0;
$secondary: #e8e8e8;
$light: #f3f3f3;
$dark: #13293d;
$info: #0055b8;
$warning: #f2c14e;
$danger: #f24236;
$success: #5e8038;
$white: #fff;
$black: #000;
$lessblack: #333333;
$itembordercolor: #949494;
$link-color: $info;
$text-muted: #C4C0B1;
$cancel: #5c636a;
$very-light: #ececec;
$help: #5a5a5a;
$card-border-color: #e5e5e5;
$modal-border-color: #afafaf;
$light-blue: #CEE3E6;
$icon-soft: #97BEC1;
$draft:#5990D1;
$brown: #a37a74;
$gray-94: #949494;

$input-focus-border-color: #80aadc;
$input-border-color: #ced4da;

$seperator-color: #cbcbcb;

$font-title: Montserrat;
$font-content: Roboto;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "white": $white,
  "black": $black,
  "lessblack": $lessblack,
  "help": $help,
  "very-light": $very-light,
  "cancel": $cancel,
  "inactive": $text-muted,
  "brown": $brown,
  "light-blue": $light-blue,
  "icon-soft": $icon-soft,
  "border-color": $input-border-color,
  "draft": $draft,
  "itembordercolor": $itembordercolor,
  "gray-94": $gray-94
);
