.text-help {
	color: var(--muted)
}

.fw-300 {
	font-weight: 300 !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.fw-900 {
	font-weight: 900 !important;
}

.ff-content {
	font-family: $font-content !important;
}

.ff-title {
	font-family: $font-title !important;
}

.fs-9 {
	font-size: 9px !important;
}

.fs-10 {
	font-size: 10px !important;
}

.fs-12 {
	font-size: 12px !important;
}

.fs-13 {
	font-size: 13px !important;
}

.fs-14 {
	font-size: 14px !important;
}

.fs-15 {
	font-size: 15px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.fs-18 {
	font-size: 18px !important;
}

.fs-20 {
	font-size: 20px !important;
}

.fs-22 {
	font-size: 22px !important;
}

.fs-24 {
	font-size: 24px !important;
}

.fs-25 {
	font-size: 25px !important;
}

.fs-26 {
	font-size: 26px !important;
}

.font-form-help {
	font: normal normal normal 10px/13px $font-content;
	color: var(--black);
	margin-bottom: 0.25rem;
}

.font-modal-subheader {
	font: normal normal normal 16px/21px $font-content;
	color: var(--black);
}

.font-page-header {
	font-family: $font-title;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	color: var(--white);
}

.font-page-subheader {
	font-family: $font-title;
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	color: var(--black);
}

.collapsed-page-subheader {
	font-size: 11.5px;
	font-weight: normal;
}

.font-logo-application {
	font-family: $font-title;
	font-style: normal;
	font-weight: normal;
	font-size: 45px;
	line-height: 66px;
	color: var(--black);
	margin-left: 1rem;
}

.font-logo-byline {
	font-family: $font-title;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: var(--black);
}


hr {
	background-color: $seperator-color;
	height: 2px !important;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

.cursor-grab {
	cursor: grab !important;
}


.label {
	font: normal normal 700 13.5px $font-title;
	color: var(--black);
	margin-bottom: .25rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-title;
	font-weight: 600
}

h1 {
	font-size: $font-size-base * 2.25;
}

h2 {
	font-size: $font-size-base * 2;
}

h3 {
	font-size: $font-size-base * 1.75;
}

h4 {
	font-size: $font-size-base * 1.5;
}

h5 {
	font-size: $font-size-base * 1.25;
}

h6 {
	font-size: $font-size-base;
}

.place-holder-white {
	&::placeholder {
		color: rgba(255, 255, 255, 0.7);
	}

	&::-ms-input-placeholder {
		color: rgba(255, 255, 255, 0.7);
	}
}

.ws-pre-line {
	white-space: pre-line;
}