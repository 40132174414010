.label-left {
    margin-left: -.5rem;
    margin-right: .5rem;
}

.icon-right {
    margin-right: -.75rem;
    margin-left: .5rem;
}

.label-right {
    margin-right: -.5rem;
    margin-left: .5rem;
}

.icon-left {
    margin-left: -.75rem;
    margin-right: .5rem;
}

.btn-draft {
    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-primary);
    --bs-btn-bg: var(--bs-white);
    --bs-btn-border-color: #{shade-color($primary, 15%)};
    --bs-btn-border-radius: .5rem;
    border-width: 2px;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #{shade-color($draft, 10%)};
    --bs-btn-hover-border-color: #{shade-color($draft, 10%)};
    --bs-btn-focus-shadow-rgb: var(--draft-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color($draft, 20%)};
    --bs-btn-active-border-color: #{shade-color($draft, 20%)};
}

.btn-pending {
    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-dark);
    --bs-btn-bg: var(--bs-white);
    --bs-btn-border-color: #{shade-color($dark, 15%)};
    --bs-btn-border-radius: .5rem;
    border-width: 2px;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #{shade-color($dark, 10%)};
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-shadow-rgb: var(--draft-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color($draft, 20%)};
    --bs-btn-active-border-color: #{shade-color($draft, 20%)};
}

.input-group-text {
    border-radius: 10px;
}

.btn {
    border-radius: 10px;
    font: normal normal normal 14px/19px $font-content;

    &.rounded-circle {
        padding: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        height: 46px;
        width: 46px;

        &.btn-sm {
            height: 30px;
            width: 30px;
        }
    }

    &.rounded-circle, &.btn-highlight {
        &:hover {
            outline: solid 2px var(--primary);
            
            &.text-danger {
                outline: solid 2px var(--danger);
            }
        }
    }

    &.form-control {
        border: 1px solid #b1b9c1;
        background-color: #fff;

        &:focus,
        &:hover {
            color: #212529;
            border-color: #80aadc;
            background-color: #fff;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(0, 122, 217, 0.25);
        }
    }

    &.no-focus-outline {
        &:focus {
            box-shadow: none;
        }
    }
}

.btn.btn-primary,
.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:active,
.btn.btn-brown,
.btn.btn-outline-brown:hover,
.btn.btn-outline-brown:active,
.btn.btn-success,
.btn.btn-cancel,
.btn.btn-danger,
.btn-outline-danger.dropdown-toggle.show {
    color: var(--white);
}

.btn.btn-outline {
    background-color: var(--white);
    border: solid 1px transparent;

    &:hover {
        background-color: var(--white);
        outline: solid 2px var(--primary);
        color: var(--primary);
    }
}


.btn.btn-accordion {
    background-color: var(--white);
    font-weight: 500;
    border: solid 1px transparent;
    font-size: 15px;


    &:hover {
        background-color: var(--white);
        outline: solid 2px var(--primary);
        color: var(--primary);
    }

    &.closed {
        color: var(--primary);
    }

    &.open {
        border: solid 1px var(--dark);

        &:hover {
            border: solid 1px var(--primary);
            outline: solid 1px var(--primary);
        }
    }
}

.btn-filled {
    background-color: var(--white);
}

.btn-filled-dark {
    background-color: var(--secondary);
}

.btn-help {
    border-radius: 0px;
}

.btn-warning .btn-icon {
    color: #845e04;
}

.btn-icon {
    font-size: 20px;

    span {
        font: normal normal normal 12px/12px Roboto;
        color: var(--black);
    }
}

td {
    .btn-icon {
        font-size: 15px;
        padding: 0;
    }
}

.btn-close {
    font-size: 25px !important;
    color: var(--help);
    background: none;
}

.btn-floating {
    box-shadow: 0px 3px 6px #00000029;
}

.edit-text {
    font-size: 0.8rem;

    &:hover {
        outline: solid 1px $primary;
    }
}

.pulse {
    animation: pulse 1s 3;

    &.tenet {
        animation: pulse 1s infinite;
    }

    &:hover {
        animation: none;
    }
}

.pulse-db {
    animation: pulsedb 1s 3;

    &.tenet {
        animation: pulsedb 1s infinite;
    }

    &:hover {
        animation: none;
    }
}

.btn {

    &:disabled,
    &.disabled {
        cursor: not-allowed !important;
        opacity: 0.40 !important;
    }
}

fieldset:disabled .btn {
    cursor: not-allowed !important;
    opacity: 0.40 !important;
}

nav ul.pagination li.disabled {
    opacity: .5;
    cursor: not-allowed
}

.btn-group.admin-review,
.btn-group.admin-review.lg {
    .btn {
        border-color: #707070;
    }

    .btn-check:not(:checked)+.btn:not(:hover) {
        color: var(--black);
        background-color: var(--white);
    }

    .btn-check:checked+.btn-outline-very-light,
    .btn-check+.btn-outline-very-light:hover {
        color: var(--primary);
    }

    .btn-check+.btn-outline-very-light:hover {
        background-color: var(--muted);
        color: var(--dark);
    }

    .btn.btn-outline-danger:hover,
    .btn.btn-outline-danger:active,
    .btn-check:checked+.btn-outline-danger,
    .btn-check+.btn-outline-danger:hover {
        color: var(--white) !important;
        background-color: tint-color($danger, 65%) !important;
    }



    .btn.btn-outline-success:hover,
    .btn.btn-outline-success:active,
    .btn-check:checked+.btn-outline-success,
    .btn-check+.btn-outline-success:hover {
        color: var(--black);
        background-color: tint-color($success, 65%) !important;
    }

    .btn {
        font-size: 10px;
        line-height: 13px;
        font-weight: 600;

        &:first-of-type {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-of-type {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}

.btn-group.admin-review-lg .btn {
    font-size: 14px;
    line-height: normal;
    padding: .75rem 1rem;
    border-color: var(--muted);
}

.btn-group.soft-rounded .btn {
    border-radius: 5px;
}

.btn.btn-danger:hover,
.btn.btn-outline-danger:hover {
    background-color: darken($danger, 15%) !important;
    color: #fff !important;
    border-color: transparent;
}

.btn.slim-tab {
    border: none;
    border-bottom: solid 3px transparent;
    border-radius: 0;

    &:not(.is-active):hover {
        outline: 1px var(--primary) solid;
        border-bottom-color: transparent;
        border-radius: 5px;
    }

    &.is-active {
        color: var(--primary);
        font-weight: 700;
        border-bottom-color: var(--primary);
        border-radius: 0;
    }
}


.btn-large svg {
    font-size: 1.5rem;
}

.double-btn .btn:hover {
    background-color: darken($primary, 12%);
    border-color: darken($primary, 12%);
}

// .btn-outline-primary:disabled:hover {
//     color: $primary  !important;
// }

@media only screen and (max-width: 680px) {
    .accordion-menu-item {
        width: 100%;
    }

    .btn.btn-accordion {
        margin-bottom: .75rem;
        width: 100%;

        .d-flex {
            justify-content: space-between;
        }
    }
}


.btn-color-transition {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.hover-bg-secondary:hover {
    background-color: var(--secondary);
}