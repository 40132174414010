.lcms-fade{
	z-index:1000
}
.lcms-tooltip {
	background-color: #000 !important;
	color: #fff !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	letter-spacing: 0.05px !important;
	font-family: "Roboto" !important;
	padding: 0.25rem 0.5rem 0.25rem 0.25rem !important;
	border-color: transparent !important;
	white-space: nowrap;

	.lcms-arrow {
		&.left {
			border-right-color: #000 !important;

			&::after {
				border-right-color: #000 !important;
			}
		}

		&.right {
			border-left-color: #000 !important;

			&::after {
				border-left-color: #000 !important;
			}
		}

		&.up {
			border-bottom-color: #000 !important;

			&::after {
				border-bottom-color: #000 !important;
			}
		}

		&.down {
			border-top-color: #000 !important;

			&::after {
				border-top-color: #000 !important;
			}
		}
	}
}
