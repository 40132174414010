@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-10px);
	}

	60% {
		transform: translateY(-7px);
	}
}

@keyframes slide-left {
	0% {
		opacity: 0.35;
		transform: scale(0.5, 0.5);
	}

	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(2, 90, 162, 0.25);
	}

	50% {
		box-shadow: 0 0 0 4px rgba(2, 90, 162, 0.3);
	}

	100% {
		box-shadow: 0 0 0 3px rgba(2, 90, 162, 0.15);
	}
}

@keyframes pulsedb {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 51, 112, 0.25);
	}

	50% {
		box-shadow: 0 0 0 4px rgba(0, 51, 112, 0.3);
	}

	100% {
		box-shadow: 0 0 0 3px rgba(0, 51, 112, 0.15);
	}
}
