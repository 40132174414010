html,
body,
div#root,
.page-root {
  height: 100%;
  letter-spacing: 0px;
}

.main-content {
  transition: all 0.3s cubic-bezier(0.175, 0.4425, 0.16, 1.275);
  display: flex;

  @media (min-width: 768px) {
    margin-left: 4rem;
  }

  .content {
    overflow-y: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &.fw {
    margin-left: 20rem;
  }

}

.scroll-nav {
  overflow: auto;
  min-height: 0;
}

.sb-footer {
  font-size: .875rem;
}

.r-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.form-list .data-item:last-of-type .data-item-content {
  border-color: transparent !important;
}


@media only screen and (max-width: 430px) {
  .inline-modal-footer button {
    width: 98% !important;
    margin: 5px;

    &.btn-warning {
      position: relative;
      right: 20px;
    }
  }
}

@media only screen and (max-width: 428px) {
  .btn.input-cta-button {
    .d-none.input-cta-label {
      display: inline-block !important;
    }

    margin-top: .5rem;
    background-color: #025aa012;
    border: none;
    outline: none;

    .fa-left-long {
      transform: rotate(90deg);
    }

    .fa-memo-circle-check {
      left: 0.5rem;
    }
  }
}

@media only screen and (max-width: 1082px) {
  .main-content.fw .ape-row .col {
    flex: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .main-content.fw .ape-row .col {
    flex: 100%;
  }
}

@media only screen and (max-width: 680px) {
  .main-content .ape-row .col {
    flex: 50%;
  }

  .data-item {
    .di-one {
      width: 100%;

      .description-modal .btn {
        font-weight: 700;
      }
    }

    .di-two {
      margin-top: .5rem;
      width: 100%;

      .btn {
        float: right;
      }
    }
  }

  .icon-header {
    display: none;
  }

  .subhead-stamp {
    width: 100%;
    margin: .5rem 0;
    padding-bottom: .5rem;
    border-bottom: solid 2px var(--gray-94)
  }

  .link-tabs .slim-tab {
    width: 100%;
    margin: .5rem !important;
    padding: .5rem !important;
    font-size: 12px;

    &.is-active {
      border: solid 1px $primary;
      border-radius: 5px !important;
    }
  }
}

@media only screen and (max-width: 576px) {
  .main-content .ape-row .col {
    flex: 100%;
  }
}

.toolbar {
  &>.btn-group {
    border-radius: 0;
    &>.btn {
      border-radius: 0;
      border: none;
    }

    &:first-child {
      &>.btn:first-child {
        border-top-left-radius: var(--bs-border-radius);
        border-bottom-left-radius: var(--bs-border-radius);
      }
    }

    &:last-child {
      &>.btn:last-child {
        border-top-right-radius: var(--bs-border-radius);
        border-bottom-right-radius: var(--bs-border-radius);
      }
    }
  }
}